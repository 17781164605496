import React from 'react';

import {
  wrap, subTitle, title, contents, back,
} from './style.module.css';

const NotFound = () => (
  <div className={wrap}>
    <p className={subTitle}>
      404 ERROR!
    </p>
    <h1 className={title}>
      길을 잃었다. 어딜 가야 할까...
    </h1>
    <p className={contents}>
      요청하신 페이지를 찾을 수 없습니다.
      <br />
      입력하신 주소가 정확한지 다시 확인해주세요.
    </p>
    {/* eslint-disable-next-line no-restricted-globals,no-undef */}
    <button type="button" className={back} onClick={() => history.back()}>
      뒤로가기
    </button>
  </div>
);

export default NotFound;
