import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import NotFound from '../containers/NotFound';

function NotFoundPage() {
  return (
    <Layout
      showingFooter={false}
      showingFloatingButton={false}
    >
      <Head
        title="뱅크샐러드 | 404"
        description="금융을 넘어 건강 자산까지 - 뱅크샐러드는 데이터를 기반으로 불균등한 정보의 비대칭을 해결해 자산 관리의 경험을 바꿔나가고 있습니다."
        keywords={['뱅크샐러드 채용', '핀테크', '스타트업', '개발자', '디자이너', 'PM', '기획자', '입사', '병특', '병역특례', '산업기능요원', '뱅샐', 'banksalad', 'TO', '채용공고']}
      />
      <NotFound />
    </Layout>
  );
}

export default NotFoundPage;
